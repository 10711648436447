import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import SudokuApp from './components/SudokuApp';
import './index.less';

function handleChange(state) {
  const history = state.history.slice();
  const compresedHistory = history.filter((_, i, arr) => { return [0, arr.length - 1].includes(i); });
  const stateCookie = {
    puzzleId: state.puzzleId,
    puzzleDifficulty: state.puzzleDifficulty,
    history: compresedHistory,
    time: state.time,
    showTimer: state.showTimer
  };
  cookies.set('state', stateCookie, { path: '/', domain: document.domain });
}

function renderApp(puzzleId, puzzleDifficulty, initializeState, puzzleBaseUrl) {
  ReactDOM.render(
    <SudokuApp
    puzzleId={puzzleId}
    puzzleDifficulty={puzzleDifficulty}
    state={initializeState}
    puzzleBaseUrl={puzzleBaseUrl}
    onChange={(state) => handleChange(state)}
    />, document.getElementById('sudoku-app')
    );
  }
    
const cookies = new Cookies();
const appStateCookie = cookies.get('state') || null;

let path = window.location.pathname;
path = path.startsWith('/') ? path.split('/').slice(1) : path.split('/');
const puzzleDifficulty = path.length > 0 ? path[0] : null;
const reqPuzzleId = path.length > 1 ? path[1] : (puzzleDifficulty ? null : path[0]);
const port = process.env.PORT || '4000'
const apiUrl = process.env.NODE_ENV === 'production' ? '/api/puzzle' : `http://localhost:${port}/api/puzzle`
renderApp(reqPuzzleId, puzzleDifficulty, appStateCookie, apiUrl);
