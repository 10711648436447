import React, { Component } from 'react';
import { getBoardPosition } from '../lib/utils'

function Cell(props) {
  const cls = `board-cell ${props.hint ? 'hint' : ''} ${props.highlight ? 'highlight' : ''}`.trim();
  return (
    <input
      className={cls}
      value={props.value ? props.value : ''}
      disabled={props.hint || props.disabled}
      onChange={props.onChange}
      onClick={() => document.execCommand("selectall", null, false)}
      type="text"
      maxLength="1"
      pattern="[0-9]*"
      autoComplete="off"
    />
  );
}

class Box extends Component {
  renderCell(cellData) {
    return (
      <Cell
        key={cellData.index}
        index={cellData.index}
        hint={cellData.hint}
        disabled={cellData.disabled}
        highlight={cellData.highlight}
        value={cellData.value}
        onChange={() => this.props.onCellChange(cellData.index)}
      />
    );
  }

  render() {
    return (
      <div className="board-box">
        {
          this.props.box.map(cellData => this.renderCell(cellData))
        }
      </div>
    )
  }
}

class Board extends Component {
  getBoxes(cells) {
    const boxes = Array(9).fill([]);
    cells.forEach((v, i) => {
      let cellBox = getBoardPosition(i)[2];
      let boxCells = boxes[cellBox].slice();
      boxCells.push({
        index: i,
        value: v,
        hint: this.props.hintCells[i],
        disabled: this.props.disabledCells[i],
        highlight: this.props.highlights.includes(i),
      });
      boxes[cellBox] = boxCells.slice();
    });
    return boxes;
  }

  render() {
    return (
      <div className="sudoku-board">
        {
          this.getBoxes(this.props.cells).map((box, i) => {
            return (
              <Box
                key={i}
                box={box}
                onCellChange={(index) => this.props.onCellChange(index)}
              />
            );
          })
        }
      </div>
    );
  }
}

export default Board;
