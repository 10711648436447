function getBoardPosition(index) {
  let r = ~~(index / 9);
  let c = index - (r * 9);
  let b = 3 * ~~(r / 3) + ~~(c / 3);
  return ([r, c ,b]);
}

function mapArray(cells) {
  const boardMap = {
    rows: [[],[],[],[],[],[],[],[],[]],
    cols: [[],[],[],[],[],[],[],[],[]],
    boxes: [[],[],[],[],[],[],[],[],[]],
    cells: []
  }
  cells.forEach((value, index) => {
    const pos = getBoardPosition(index);
    boardMap.rows[pos[0]].push(value);
    boardMap.cols[pos[1]].push(value);
    boardMap.boxes[pos[2]].push(value);
    boardMap.cells.push(index);
  });
  return boardMap;
}

function hasDuplicates(arr) {
  while (arr.length > 0) {
    let item = arr.pop();
    if (arr.includes(item)) {
      return true;
    }
  }
  return false;
}

function fetchPuzzleById(id, puzzleBaseUrl, callback) {
  const puzzleUrl = puzzleBaseUrl + (id ? `/${id}` : '');
  fetch(puzzleUrl, { method: 'GET' })
    .then(res => res.json())
    .then(puzzle => callback(puzzle))
}

function fetchPuzzleByDifficulty(difficulty, puzzleBaseUrl, callback) {
  const puzzleUrl = puzzleBaseUrl + `?difficulty=${difficulty}`;
  fetch(puzzleUrl, { method: 'GET' })
    .then(res => res.json())
    .then(puzzle => callback(puzzle))
}

export { getBoardPosition, mapArray, hasDuplicates, fetchPuzzleById, fetchPuzzleByDifficulty }