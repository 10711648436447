import React, { Component } from 'react';
import { FaCheck, FaPause, FaPlay, FaRedo, FaForward, FaStopwatch } from 'react-icons/fa';
import Board from './Board';
import { getBoardPosition, mapArray, hasDuplicates, fetchPuzzleById, fetchPuzzleByDifficulty } from '../lib/utils'

class SudokuApp extends Component {
  constructor(props) {
    super(props);
    const reqPuzzleId = this.props.puzzleId;
    const reqPuzzleDifficulty = this.props.puzzleDifficulty;
    const importedState = this.props.state || {};
    const importedHistory = 'history' in importedState ? importedState.history.slice() : [];
    const importedPuzzleId = 'puzzleId' in importedState ? importedState.puzzleId : null;
    const importedPuzzleDifficulty = 'puzzleDifficulty' in importedState ? importedState.puzzleDifficulty : null;
    const history = (importedHistory.length > 0 && importedPuzzleId)
    && ((reqPuzzleId && reqPuzzleId === importedPuzzleId) || !reqPuzzleId)
    ? importedHistory : [{ squares: Array(81).fill(0) }];

    this.state = {
      puzzleId: reqPuzzleId || (!reqPuzzleDifficulty || reqPuzzleDifficulty === importedPuzzleDifficulty ? importedPuzzleId : null),
      puzzleDifficulty: reqPuzzleId ? null : (['easy', 'medium', 'hard', 'extreme'].includes(reqPuzzleDifficulty) ? reqPuzzleDifficulty : 'medium'),
      history: history,
      showTimer: 'showTimer' in importedState ? importedState.showTimer : false,
      time: 'time' in importedState ? importedState.time : 0,
      paused: true,
      fetchError: null,
      status: this.initStatus()
    };
    this.handleCellChange = this.handleCellChange.bind(this);
  }

  initStatus() {
    return {
      completed: false,
      highlights: [],
      mistakes: [],
      message: null,
    };
  }

  componentDidMount() {
    const puzzleId = this.state.puzzleId;
    const puzzleDifficulty = this.state.puzzleDifficulty;
    if (puzzleId) {
      this.loadPuzzleById(puzzleId);
    } else {
      this.loadPuzzleByDifficulty(puzzleDifficulty);
    }
    this.unPause();
  }

  componentWillUpdate(props, state) {
    if (typeof(this.props.onChange) == 'function') {
      this.props.onChange(state);
    }
  }

  loadPuzzleByDifficulty(difficulty) {
    fetchPuzzleByDifficulty(difficulty || this.state.puzzleDifficulty, this.props.puzzleBaseUrl, puzzle => {
      const fetchError = !puzzle.id;
      this.puzzle = fetchError ? { id: 'na', initial: Array(81).fill(0), solution: Array(81).fill(0) } : puzzle;
      this.setState({
        puzzleId: this.puzzle.id,
        puzzleDifficulty: fetchError ? this.state.puzzleDifficulty : this.puzzle.difficulty,
        history: [{ squares: this.puzzle.initial }],
        time: 0,
        fetchError: fetchError,
        status: this.initStatus()
      });
      window.history.pushState('rewrite', 'Title', `/${this.puzzle.difficulty}/${this.puzzle.id}`);
    });
  }
  
  loadPuzzleById(id) {
    const puzzleHistory = id === this.state.puzzleId && !this.state.history[0].squares.every(s => s === 0)
      ? this.state.history.slice() : null;
    fetchPuzzleById(id, this.props.puzzleBaseUrl, puzzle => {
      const fetchError = !puzzle.id;
      this.puzzle = fetchError ? { id: id, initial: Array(81).fill('X'), solution: Array(81).fill(0) } : puzzle;
      this.setState({
        puzzleId: this.puzzle.id,
        puzzleDifficulty: fetchError ? this.state.puzzleDifficulty : this.puzzle.difficulty,
        history: !fetchError && puzzleHistory ? puzzleHistory : [{ squares: this.puzzle.initial }],
        time: !fetchError && puzzleHistory ? this.state.time : 0,
        fetchError: fetchError,
        status: this.initStatus()
      });
      window.history.pushState('rewrite', 'Title', `/${this.puzzle.difficulty}/${this.puzzle.id}`);
    });
  }

  handleCellChange(index) {
    const history = this.state.history.slice();
    const current = history[history.length - 1];
    const squares = current.squares.slice();
    // squares[index] = (event.target.value ? ~~event.target.value : 0);
    squares[index] = (event.data ? ~~event.data : 0);
    this.setState({
      history: history.concat([{
        squares: squares,
      }]),
      status: this.initStatus()
    });  
    this.unPause();
  }

  clearBoard() {
    this.setState({
      time: 0,
      history: [{
        squares: this.puzzle.initial.slice()
      }],
      status: this.initStatus()
    })
  }

  timerSet() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        let newTime = this.state.time + 1
        this.setState({
          time: newTime
        });
      }, 1000);
    }
  }

  timerClear() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
  
  pause() {
    this.setState({
      paused: true,
    });
    this.timerClear();
  }
  
  unPause() {
    this.setState({
      paused: false,
    });
    this.timerSet();
  }
  
  getMistakes(squares) {
    const solution = this.puzzle.solution.slice();
    return [...Array(81).keys()].filter(index => {
      return (squares[index] != 0 && solution[index] != squares[index])
    });
  }

  getVisibleMistakes(squares) {
    let elemToHighlight = {
      rows: [],
      cols: [],
      boxes: []
    };
    let highlights = [];
    const currentBoard = mapArray(squares);
    ['rows', 'cols', 'boxes'].forEach(unit => {
      currentBoard[unit].forEach((arr, i) => {
        if (hasDuplicates(arr.filter(v => v != 0))) {
          elemToHighlight[unit].push(i)
        }
      })
    });
    squares.forEach((_, index) => {
      let squarePosition = getBoardPosition(index);
      ['rows', 'cols', 'boxes'].forEach((unit, unitIndex) => {
        if (elemToHighlight[unit].includes(squarePosition[unitIndex])) {
          highlights.push(index)
        }
      })
    });
    return highlights;
  }

  handleCheckButton(squares) {
    const highlights = this.getVisibleMistakes(squares).slice();
    const mistakes = this.getMistakes(squares).slice();
    if (squares.every(v => v !== 0) && mistakes.length === 0) {
      this.setState({
        status: {
          completed: true,
          highlights: [],
          mistakes: [],
          message: null,
        }
      });
      this.pause();
    } else {
      console.log('not done')
      let progressMessage;
      if (highlights.length) {
        progressMessage = 'There are some visibale mistakes!';
      } else if (mistakes.length) {
        progressMessage = `You have ${mistakes.length} mistake${mistakes.length > 1 ? 's' : ''}!`;
      } else {
        const emptyCells = this.state.history[this.state.history.length - 1].squares.filter(v => v === 0).length;
        progressMessage = `You doing well! ${emptyCells} cell${emptyCells > 1 ? 's' : ''} to go...`;
      }
      this.setState({
        status: {
          completed: false,
          highlights: highlights,
          mistakes: mistakes,
          message: progressMessage,
        }
      });
    }
  }

  handlePauseButton() {
    this.state.paused && !this.state.status.completed ? this.unPause() : this.pause();
  }

  handleClearButton() {
    this.clearBoard();
    this.unPause();
  }

  handleNewButton(difficulty) {
    this.setState({
      history: [{ squares: Array(81).fill(0) }]
    })
    this.loadPuzzleByDifficulty(difficulty);
    this.unPause();
  }

  handleTimerButton() {
    this.setState({
      showTimer: !this.state.showTimer
    });
  }

  getStatus() {
    let statusLine = 'グッドラック';
    if (this.state.status.message) {
      statusLine = this.state.status.message;
    } else if (this.state.fetchError) {
      statusLine = 'PUZZLE NOT FOUND :(';
    } else if (this.state.paused && !this.state.status.completed) {
      statusLine = 'GAME PAUSED';
    } else if (this.state.status.completed) {
      statusLine = 'GOOD JOB!';
    }
    return statusLine;
  }

  getTimer() {
    const date = new Date(null);
    date.setSeconds(this.state.time);
    return parseInt(this.state.time) < 3600 ? date.toISOString().substr(14, 5) : date.toISOString().substr(11, 8);
  }

  getCompleteMessage() {
    return `Congratulations, you made it!`;
  }

  render() {
    const history = this.state.history;
    const initial = history[0].squares;
    const squares = history[history.length - 1].squares;

    return (
      <div className="sudoku-app">
        <div className="game-difficulty">
          <button className={`difficulty-btn ${(this.state.puzzleDifficulty === 'easy' ? 'difficulty-current' : '')}`.trim()} onClick={() => this.handleNewButton('easy')}>EASY</button>
          <button className={`difficulty-btn ${(this.state.puzzleDifficulty === 'medium' ? 'difficulty-current' : '')}`.trim()} onClick={() => this.handleNewButton('medium')}>MEDIUM</button>
          <button className={`difficulty-btn ${(this.state.puzzleDifficulty === 'hard' ? 'difficulty-current' : '')}`.trim()} onClick={() => this.handleNewButton('hard')}>HARD</button>
          <button className={`difficulty-btn ${(this.state.puzzleDifficulty === 'extreme' ? 'difficulty-current' : '')}`.trim()} onClick={() => this.handleNewButton('extreme')}>EXTREME</button>
        </div>
        <div className="game-status">
          <div className={`status-line ${this.state.showTimer ? 'show-timer' : ''}`.trim()}><h1 className="status">{this.getStatus()}</h1></div>
          <div className="game-timer"><h1 className="status">{this.state.showTimer ? this.getTimer() : ''}</h1></div>
        </div>
        <div className={`complete-message ${this.state.status.completed ? 'completed' : ''}`.trim()}><p>{this.getCompleteMessage()}</p></div>
        <Board
          cells={this.state.paused && !this.state.status.completed ? Array(81).fill('O') : squares}
          hintCells={this.state.paused ? Array(81).fill(true) : initial.map(v => parseInt(v) !== 0)}
          disabledCells={this.state.paused || this.state.status.completed ? Array(81).fill(true) : Array(81).fill(false)}
          highlights={this.state.status.highlights}
          onCellChange={(i) => this.handleCellChange(i)}
        />
        <div className="game-controls">
          <button className="control-btn" onClick={() => this.handleCheckButton(squares)}><FaCheck/> CHECK</button>
          <button className="control-btn" onClick={() => this.handlePauseButton()}>{this.state.paused ? FaPlay() : FaPause()} {this.state.paused ? 'RESUME' : 'PAUSE'}</button>
          <button className="control-btn" onClick={() => this.handleClearButton()}><FaRedo/> CLEAR</button>
          <button className="control-btn" onClick={() => this.handleNewButton()}><FaForward/> NEW</button>
          <button className="control-btn" onClick={() => this.handleTimerButton()}><FaStopwatch/> {this.state.showTimer ? 'HIDE' : 'SHOW'}</button>
        </div>
      </div>
    );
  }
}

export default SudokuApp;
